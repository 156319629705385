<template>
    <a-card>
        <a-tabs :default-active-key="tabsType">
            <a-tab-pane key="1" tab="系统公告">
                <textNotice></textNotice>
            </a-tab-pane>
            <a-tab-pane key="2" tab="图片广告">
                <imgNotice></imgNotice>
            </a-tab-pane>
        </a-tabs>
    </a-card>

</template>
<script>
    import { ref } from "vue";
    import textNotice from './components/textNotice';
    import imgNotice from './components/imgNotice'
    export default {
        components: { textNotice, imgNotice },
        setup() {
            const tabsType = ref('1')
            return {
                tabsType
            };
        },
    };
</script>